import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import( /* webpackChunkName: "home" */ '@/views/Home.vue'),
    children: [{
        path: '/GeneraAsientos',
        name: 'GeneraAsientos',
        component: () => import( /* webpackChunkName: "GeneraAsientos" */ '@/views/AdminGeneraAsientos.vue')
      },
      {
        path: '/RechazadosDiria',
        name: 'RechazadosDiria',
        component: () => import( /* webpackChunkName: "RechazadosDiria" */ '@/views/RechazadosDiria.vue')
      },
      {
        path: '/RechazadosJde',
        name: 'RechazadosJde',
        component: () => import( /* webpackChunkName: "RechazadosJde" */ '@/views/RechazadosJde.vue')
      },
      {
        path: '/RevisionDiria',
        name: 'RevisionDiria',
        component: () => import( /* webpackChunkName: "RevisionDiria" */ '@/views/RevisionDiria.vue')
      },
      {
        path: '/VentasSegmentoReal',
        name: 'VentasSegmentoReal',
        component: () => import( /* webpackChunkName: "VentasSegmentoReal" */ '@/views/VentasSegmentoReal.vue')
      },
      {
        path: '/ConfiguracionDiria',
        name: 'ConfiguracionDiria',
        component: () => import( /* webpackChunkName: "ConfiguracionDiria" */ '@/views/ConfiguracionDiria.vue')
      },
      {
        path: '/ConfiguracionJde',
        name: 'ConfiguracionJde',
        component: () => import( /* webpackChunkName: "ConfiguracionJde" */ '@/views/ConfiguracionJde.vue')
      },
      {
        path: '/DescMicrosArchivo',
        name: 'DescMicrosArchivo',
        component: () => import( /* webpackChunkName: "DescMicrosArchivo" */ '@/views/DescMicrosArchivo.vue')
      },
      {
        path: '/ReporteCostoPedidos',
        name: 'ReporteCostoPedidos',
        component: () => import( /* webpackChunkName: "DescMicrosArchivo" */ '@/views/ReporteCostoPedidos.vue')
      },
      {
        path: '/ReporteVentasAyBDiria',
        name: 'ReporteVentasAyBDiria',
        component: () => import( /* webpackChunkName: "DescMicrosArchivo" */ '@/views/ReporteVentasAyBDiria.vue')
      },
      {
        path: '/ReporteSaloneros',
        name: 'ReporteSaloneros',
        component: () => import( /* webpackChunkName: "DescMicrosArchivo" */ '@/views/ReporteSaloneros.vue')
      },
      {
        path: '/ReporteSalonerosDetalle',
        name: 'ReporteSalonerosDetalle',
        component: () => import( /* webpackChunkName: "DescMicrosArchivo" */ '@/views/ReporteSalonerosDetalle.vue')
      },
      {
        path: '/ReportePropinas',
        name: 'ReportePropinas',
        component: () => import( /* webpackChunkName: "ReportePropinas" */ '@/views/ReportePropinas.vue')
      },
      {
        path: '/Budget',
        name: 'Budget',
        component: () => import( /* webpackChunkName: "Budget" */ '@/views/Budget.vue')
      },
      {
        path: '/ReporteSalonerosJde',
        name: 'ReporteSalonerosJde',
        component: () => import( /* webpackChunkName: "DescMicrosArchivo" */ '@/views/ReporteSalonerosJde.vue')
      },
      {
        path: '/ReporteSalonerosDetalleJde',
        name: 'ReporteSalonerosDetalleJde',
        component: () => import( /* webpackChunkName: "DescMicrosArchivo" */ '@/views/ReporteSalonerosDetalleJde.vue')
      },
    ]
  },
  {
    path: '*',
    component: () => import( /* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
  },


]

const router = new VueRouter({

  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {

  const res = await Store.dispatch('AuthModule/verifyToken')

  if (to.name !== 'Login') {
    if (res.state) {
      next()
    } else {
      alert(res.message)
      router.history.push('/')
    }
  } else {
    if (res.state) {
      router.history.push('/home')
    } else {
      next()
    }
  }
})
export default router